<template>
	<div class="w-full">
		<div class="flex justify-between">
			<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Медицинские организации</h1>
			<div class="flex">
				<Button v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="mr-2" text="Добавить МО" icon="add" @click.native="openPopUp()" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<!-- <div class="flex w-full h-auto mt-4 px-7 py-5 rounded-lg bg-white filter shadow-default"> -->
			<div class="relative w-full rounded-lg bg-white">
				<div class="t-overflow w-full">
					<table :style="medOrgs.length > 0 ? 'min-height: 400px' : ''">
						<!-- Заголовки -->
						<tr>
							<th
								v-for="(item, key) in fields"
								:key="'A' + key"
								class="table-cell th-content sm:relative"
								:class="{ 'td-name td-name-head z-20': key === 0, 'border-b sm:border-gray-100': key !== 0 }"
							>
								<p class="w-full my-auto text-gray-400 cursor-pointer font-normal justify-between items-center" @click="switchFilterVisibility(key)">
									<span class="underline" :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }">
										{{ item.Name }}
									</span>
									<!-- eslint-disable max-len -->
									<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
											fill="#9193A5"
										/>
									</svg>
									<!-- eslint-enable max-len -->
								</p>
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									:position="key === 0 ? 'left' : 'right'"
									:id-filter="key"
									:param="params"
									@query="getData"
									:fields="fields"
									:defaultSortField="'name'"
									:item="item"
								/>
							</th>
						</tr>
						<!-- /Заголовки -->

						<!-- Содержимое -->
						<tbody>
							<template v-for="(item, index) in medOrgs">
								<tr :key="item.id" class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500" :class="{ 'bg-gray-30': item.id === opened }" @click="openRow(item)">
									<td
										class="table-cell border-t border-gray-100 td-name z-10"
										:class="{ 'underline cursor-pointer': item.hasBranch }"
										:style="item.id === opened ? 'background: #F2F5FF' : ''"
									>
										<div class="flex">
											<Icons v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="mr-2 icon-control" icon-name="pen" @click.native="editMO(item, index)" />
											<p>{{ item.name }}</p>
										</div>
									</td>
									<td class="table-cell border-b border-gray-100 td-one">
										<p>{{ item.shortName ? item.shortName : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.address ? item.address : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.postAddress ? item.postAddress : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.ownerShipId ? setName(item.ownerShipId, 'ownership') : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.inn ? item.inn : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.webSiteAddress ? item.webSiteAddress : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.departmentBelongId ? setName(item.departmentBelongId, 'departments') : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.nomenclatureId ? setName(item.nomenclatureId, 'nomenclatures') : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.noteId ? setName(item.noteId, 'notes') : '---' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<span v-if="item.years">
											{{
												item.years
													.map((item) => {
														return item.year;
													})
													.join(', ')
											}}
										</span>
										<span v-else> - </span>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.ambulator ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.stacionar ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.healthResort ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p>{{ item.psychiatric ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p v-if="item.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || item.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
											<a href="#" @click="openBunnersPopUp(item)">Получить баннер</a>
										</p>
										<span v-else> - </span>
									</td>
									<td class="table-cell border-b border-gray-100">
										<p v-if="item.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || item.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
											<a href="#" @click="downloadQr(item)">Скачать</a>
										</p>
										<span v-else> - </span>
									</td>
								</tr>
								<template v-if="item.id === opened">
									<tr
										v-for="(subitem, subindex) in item.branches"
										:key="subitem.id"
										class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500"
										:class="{ 'bg-gray-30': item.id === opened }"
									>
										<td class="table-cell border-t border-gray-100 td-name z-10 bg-gray-30" :style="item.id === opened ? 'background: #F2F5FF' : ''">
											<div class="flex items-center">
												<div v-if="subitem.isBranch" class="default-filial-clone">Филиал</div>
												<div v-if="subitem.isClone" class="default-filial-clone-2 ml-2">Вторичная МО</div>
											</div>
											<div class="flex">
												<Icons
													v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator']) && !subitem.isClone"
													class="mr-2 icon-control"
													icon-name="pen"
													@click.native="editMO(subitem, subindex)"
												/>
												<p>{{ subitem.name }}</p>
											</div>
										</td>
										<td class="table-cell border-b border-gray-100 td-one">
											<p>{{ subitem.shortName ? subitem.shortName : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.address ? subitem.address : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.postAddress ? subitem.postAddress : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.ownerShipId ? setName(subitem.ownerShipId, 'ownership') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.inn ? subitem.inn : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.webSiteAddress ? subitem.webSiteAddress : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.departmentBelongId ? setName(subitem.departmentBelongId, 'departments') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.nomenclatureId ? setName(subitem.nomenclatureId, 'nomenclatures') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.noteId ? setName(subitem.noteId, 'notes') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<span v-if="subitem.years">
												{{
													subitem.years
														.map((subitem) => {
															return subitem.year;
														})
														.join(', ')
												}}
											</span>
											<span v-else> - </span>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.ambulator ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.stacionar ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.healthResort ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.psychiatric ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p v-if="subitem.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || subitem.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f' || !subitem.isClone">
												<a href="#" @click="openBunnersPopUp(subitem)">Получить баннер</a>
											</p>
											<span v-else> - </span>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p v-if="subitem.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || subitem.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f' || !subitem.isClone">
												<a href="#" @click="downloadQr(subitem)">Скачать</a>
											</p>
											<span v-else> - </span>
										</td>
									</tr>
								</template>
							</template>
						</tbody>
					</table>
					<center v-if="!loadingTable && medOrgs.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
				</div>
				<div class="hidden sm:flex flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(5)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 5 }">5</li>
							<li @click="updateRow(10)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 10 }">10</li>
							<li @click="updateRow(15)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 15 }">15</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
				<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
		</div>
		<PopUp :open="open" :title="popupTitle" size="70%" @close="closePopup()">
			<div class="flex w-full gap-5">
				<div class="w-6/12">
					<div class="input-box">
						<label>Регион*</label>
						<v-select
							:options="regions"
							:disabled="innClear || regionDisabled"
							v-model="infoMO.regionId"
							label="name"
							:reduce="(option) => option.value"
							class="search"
							:class="{
								'custom-error': error.regionId.val
							}"
						/>
						<p class="error-text" v-if="error.regionId.val">
							{{ error.regionId.msg }}
						</p>
					</div>
					<TextInput label="Краткое наименование МО*" :error="error.shortName.val ? error.shortName.msg : null" :title="infoMO.shortName" :disabled="innClear" v-model="infoMO.shortName" />
					<TextInput label="Почтовый адрес*" :error="error.postAddress.val ? error.postAddress.msg : null" :title="infoMO.postAddress" :disabled="innClear" v-model="infoMO.postAddress" />
					<TextInput label="ИНН*" :error="error.inn.val ? error.inn.msg : null" :title="infoMO.inn" @input="setInn(infoMO.inn)" type="number" v-model="infoMO.inn" />
					<div class="input-box" v-if="userIsInRoles(['Support', 'MZ_Employee'])">
						<label>Ведомственная принадлежность</label>
						<v-select :options="departments" :disabled="innClear" label="name" v-model="infoMO.departmentBelongId" :reduce="(option) => option.value" class="search"></v-select>
					</div>
					<div class="input-box" v-if="infoMO.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || infoMO.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
						<label class="select-label"> Включено в перечень* </label>
						<multiselect
							tag-placeholder=""
							:disabled="innClear"
							@change="setYears(infoMO.years)"
							placeholder=""
							:hide-selected="true"
							:searchable="false"
							:multiple="true"
							:options="optionsYears"
							label="year"
							track-by="year"
							v-model="infoMO.years"
							:class="{
								'custom-error-multiselect': error.years.val
							}"
						/>
						<p class="error-text" v-if="error.years.val">
							{{ error.years.msg }}
						</p>
					</div>
					<div class="flex justify-between" v-if="infoMO.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || infoMO.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
						<div class="radio-group">
							<p class="select-label">Амбулаторные*</p>
							<div class="flex">
								<label>
									<input type="radio" name="ambulator" :disabled="innClear" :value="true" v-model="infoMO.ambulator" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="ambulator" :disabled="innClear" :value="false" v-model="infoMO.ambulator" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Стационарные*</p>
							<div class="flex">
								<label>
									<input type="radio" name="stacionar" :disabled="innClear" :value="true" v-model="infoMO.stacionar" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="stacionar" :disabled="innClear" :value="false" v-model="infoMO.stacionar" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
					</div>
					<div class="radio-group">
						<p class="select-label">Филиал*</p>
						<div class="flex">
							<label>
								<input type="radio" name="filial" @change="setFilial(true)" :value="true" v-model="infoMO.isBranch" />
								<span class="ml-2">Да</span>
							</label>
							<label class="ml-2">
								<input type="radio" name="filial" @change="setFilial(false)" :value="false" v-model="infoMO.isBranch" />
								<span class="ml-2">Нет</span>
							</label>
						</div>
					</div>
				</div>
				<div class="w-6/12">
					<TextInput label="Полное наименование МО*" :error="error.name.val ? error.name.msg : null" :title="infoMO.name" :disabled="innClear" v-model="infoMO.name" />
					<TextInput label="Фактический адрес*" :error="error.address.val ? error.address.msg : null" :title="infoMO.address" :disabled="innClear" v-model="infoMO.address" />
					<div class="input-box">
						<label>Форма собственности*</label>
						<v-select
							:options="ownership"
							:disabled="innClear"
							:error="ownershipError"
							@input="ownershipOGV(infoMO.ownerShipId)"
							v-model="infoMO.ownerShipId"
							label="name"
							class="search"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.ownerShipId.val
							}"
						/>
						<p class="error-text" v-if="error.ownerShipId.val">
							{{ error.ownerShipId.msg }}
						</p>
						<p v-if="ownershipError" class="error-text">{{ ownershipError }}</p>
					</div>
					<TextInput label="Адрес официального сайта*" :error="error.webSiteAddress.val ? error.webSiteAddress.msg : null" :disabled="innClear" v-model="infoMO.webSiteAddress" />
					<div class="input-box">
						<label>Номенклатура*</label>
						<v-select
							:options="nomenclatures"
							:disabled="innClear"
							@input="notesChange(infoMO.nomenclatureId)"
							v-model="infoMO.nomenclatureId"
							label="name"
							class="search"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.nomenclatureId.val
							}"
						/>
						<p class="error-text" v-if="error.nomenclatureId.val">
							{{ error.nomenclatureId.msg }}
						</p>
					</div>
					<div class="input-box">
						<label>Примечание*</label>
						<v-select
							:options="notes"
							:disabled="innClear || noteDisabled"
							v-model="infoMO.noteId"
							label="name"
							class="search"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.noteId.val
							}"
						/>
						<p class="error-text" v-if="error.noteId.val">
							{{ error.noteId.msg }}
						</p>
					</div>
					<div class="flex justify-between" v-if="infoMO.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || infoMO.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f'">
						<div class="radio-group">
							<p class="select-label">Психиатрические*</p>
							<div class="flex">
								<label>
									<input type="radio" name="psychiatric" :disabled="innClear" :value="true" v-model="infoMO.psychiatric" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="psychiatric" :disabled="innClear" :value="false" v-model="infoMO.psychiatric" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Санаторно-курортные*</p>
							<div class="flex">
								<label>
									<input type="radio" name="healthResort" :disabled="innClear" :value="true" v-model="infoMO.healthResort" />
									<span class="ml-2">Да</span>
								</label>
								<label class="ml-2">
									<input type="radio" name="healthResort" :disabled="innClear" :value="false" v-model="infoMO.healthResort" />
									<span class="ml-2">Нет</span>
								</label>
							</div>
						</div>
					</div>
					<div class="input-box" v-if="infoMO.isBranch">
						<label>Головная организация*</label>
						<v-select
							:options="moArr"
							:disabled="filialDisabled"
							label="name"
							v-model="infoMO.parentId"
							class="search"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.parentId.val
							}"
						/>
						<p class="error-text" v-if="error.parentId.val">
							{{ error.parentId.msg }}
						</p>
					</div>
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" :disabled="!!ownershipError" text="Сохранить" @click.native="validation(infoMO)" />
			</div>
		</PopUp>
		<PopUp :open="openBunners" size="95%" title="Получить баннер" @close="closeBunners()">
			<div class="flex justify-between">
				<Bunner :ids="bunnersId" level="mo" type="1"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="2"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="3"></Bunner>
			</div>
		</PopUp>
		<PopUp :open="openClonInn" @close="allClose()">
			<p>
				МО с таким ИНН уже существует. Создать еще одно МО с тем же ИНН можно в двух случаях: если оно является филиалом медицинского учреждения, то выберите ниже вариант «Филиал», если
				учреждение обслуживает пациентов из другого региона (отличного от региона, в котором находится учреждение), то выберите вариант «МО обслуживает другой регион»
			</p>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" text="Филиал" @click.native="filial(true)" />
				<Button size="medium" text="МО обслуживает другой регион" @click.native="filial(false)" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import FilterComponent from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';
import PopUp from '@/components/PopUp.vue';
import Button from '@/components/Button.vue';
import Bunner from '@/components/Bunner.vue';
import TextInput from '@/components/TextInput.vue';
import loadingComponent from '@/components/Loading.vue';
import { getters, actions, mutations, methods, compareAsc } from '@/store/store';
// Локальные данные стора
import localActions from '../store/actions';

export default {
	name: 'TableMain',
	components: {
		loadingComponent,
		Icons,
		Bunner,
		FilterComponent,
		Multiselect,
		Button,
		PopUp,
		TextInput
	},
	data() {
		return {
			open: false,
			openClonInn: false,
			openBunners: false,
			bunnersId: '',
			filialDisabled: false,
			rowsVisible: 5,
			totalCount: 0,
			page: 0,
			regions: [],
			infoMO: {
				name: '',
				regionId: '',
				shortName: '',
				address: '',
				postAddress: '',
				ownerShipId: '',
				inn: '',
				webSiteAddress: '',
				departmentBelongId: null,
				nomenclatureId: '',
				noteId: '',
				isBranch: false,
				isClone: false,
				isMain: true,
				ambulator: null,
				stacionar: null,
				healthResort: null,
				psychiatric: null,
				parentId: null,
				yearStr: '',
				years: []
			},
			error: {
				name: { val: null, msg: 'Введите полное наименование МО' },
				regionId: { val: null, msg: 'Выберите Регион' },
				shortName: { val: null, msg: 'Введите краткое наименование МО' },
				address: { val: null, msg: 'Введите фактический адрес' },
				postAddress: { val: null, msg: 'Введите почтовый адрес' },
				ownerShipId: { val: null, msg: 'Выберите форма собственности' },
				inn: { val: null, msg: 'Введите ИНН' },
				webSiteAddress: { val: null, msg: 'Введите адрес официального сайта' },
				nomenclatureId: { val: null, msg: 'Выберите номенклатуру' },
				noteId: { val: null, msg: 'Выберите примечание' },
				isBranch: { val: null, msg: 'Выберите филиал это или нет' },
				ambulator: { val: null, msg: 'Выберите амбулаторные условия или нет' },
				stacionar: { val: null, msg: 'Выберите стационарные условия или нет ' },
				healthResort: { val: null, msg: 'Выберите санаторно-курортные условия или нет' },
				psychiatric: { val: null, msg: 'Выберите психиатрические условия или нет' },
				parentId: { val: null, msg: 'Выберите головную организацию' },
				years: { val: null, msg: 'Выберите включено в перечень какого года' }
			},
			objectIndex: 0,
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Полное наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Краткое наименование МО',
					fieldName: 'shortName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Фактический адрес',
					fieldName: 'address',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Почтовый адрес',
					fieldName: 'postAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Форма собственности',
					fieldName: 'OwnerShipName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'ИНН',
					fieldName: 'inn',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Адрес официального сайта',
					fieldName: 'webSiteAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Ведомственная принадлежность',
					fieldName: 'DepartmentBelongName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Номенклатура',
					fieldName: 'NomenclatureName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Примечание',
					fieldName: 'NoteName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Включено в перечень',
					fieldName: 'YearStr',
					filterType: 9,
					filterValueType: 1,
					value1: null,
					value2: null,
					values: [],
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulator',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionar',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'healthResort',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatric',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Баннер',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'QR-код',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			region: null,
			medOrgs: [],
			opened: null,
			optionsYears: [],
			children: [],
			upadte: false,
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: '1038b985-5708-41ef-95cb-85ba7586702c'
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true',
						value2: ''
					}
				],
				sortOption: {
					fieldName: 'name',
					sortType: 2
				},
				pageSize: 5,
				currentPage: 0
			},
			loadToExcell: false,
			departments: [],
			nomenclatures: [],
			notes: [],
			ownership: [],
			moArr: [],
			ownershipError: null,
			noteDisabled: true,
			innClear: true,
			regionDisabled: false,
			defNomenclatures: [
				'Женская консультация',
				'Молочная кухня',
				'Станция скорой медицинской помощи',
				'Станция переливания крови (центр крови)',
				'Дом ребенка, в том числе специализированный',
				'Физиотерапевтическая поликлиника',
				'Центр медицинской и социальной реабилитации, в том числе с отделением постоянного проживания инвалидов и детей-инвалидов с тяжелыми формами детского церебрального паралича, самостоятельно не передвигающихся и себя не обслуживающих',
				'Психиатрическая больница (стационар) специализированного типа',
				'Психиатрическая больница (стационар) специализированного типа с интенсивным наблюдением',
				'Клинико-диагностическая лаборатория',
				'Бактериологическая больница, в том числе по диагностике туберкулеза',
				'Центр медицинской профилактики',
				'Центр медицины катастроф',
				'Центр медицинских мобилизационных резервов «Резерв»',
				'Медицинский информационно-аналитический центр',
				'Медицинский биофизический центр',
				'Центр военно-врачебной экспертизы',
				'Центр судебно-медицинской экспертизы',
				'Бюро медико-социальной экспертизы',
				'Бюро медицинской статистики',
				'Патологоанатомическое бюро',
				'Бюро судебно-медицинской экспертизы',
				'Центр гигиены и эпидемиологии',
				'Противочумный центр (станция)',
				'Дезинфекционный центр (станция)',
				'Центр гигиенического образования населения',
				'Центр государственного санитарно-эпидемиологического надзора'
			],
			loadingTable: false
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		...localActions,
		allClose() {
			this.closePopup();
			this.openClonInn = false;
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		setFilial(flag) {
			if (flag) {
				this.infoMO.parentId = '';
				this.infoMO.isMain = false;
			} else {
				this.infoMO.parentId = null;
				this.infoMO.isMain = true;
			}
		},
		filial(filial) {
			this.filialDisabled = filial;
			this.innClear = !filial;
			this.infoMO.isBranch = filial;
			this.infoMO.isClone = !filial;
			this.openClonInn = false;

			if (!filial) {
				let anotherRegionNote = this.notes.find((x) => x.name === 'За пределами субъекта');

				if (anotherRegionNote !== undefined) {
					this.infoMO.noteId = anotherRegionNote.value;
					this.infoMO.years = [];
				}
			}
		},
		setInn(inn) {
			if (inn.length >= 10) {
				this.getMedicalOrgsByINN(inn).then((res) => {
					let mainMO = res.data.find((x) => x.isMain);

					if (mainMO) {
						let found = this.moArr.find((x) => x.value === mainMO.id);
						if (!found) {
							this.moArr.push({ name: mainMO.name, value: mainMO.id });
							this.moArr.sort((a, b) => compareAsc(a.name, b.name));
						}

						let moId = this.infoMO.id;
						Object.assign(this.infoMO, mainMO);
						this.infoMO.id = moId;
						this.infoMO.parentId = mainMO.id;
						this.infoMO.regionId = this.region.id;
						this.infoMO.isMain = false;

						this.openClonInn = true;
					} else if (res.data.length > 0) {
						this.infoMO.inn = '';
					} else {
						this.infoMO.isMain = true;
						this.infoMO.isBranch = false;
						this.infoMO.isClone = false;
						this.innClear = false;
					}
				});
			} else {
				this.innClear = true;
				this.infoMO = {
					name: null,
					regionId: this.region.id,
					shortName: null,
					address: null,
					postAddress: null,
					ownerShipId: null,
					inn: this.infoMO.inn,
					isBranch: false,
					isClone: false,
					isMain: true,
					webSiteAddress: null,
					departmentBelongId: null,
					nomenclatureId: null,
					noteId: null,
					ambulator: false,
					stacionar: false,
					healthResort: false,
					psychiatric: false,
					parentId: null,
					deletedBy: null,
					yearStr: null,
					years: null
				};
			}
		},
		setName(id, type) {
			let name = '';
			this[type].forEach((item) => {
				if (item.value === id) name = item.name;
			});
			return name;
		},
		openConsole(text) {
			console.log(text);
		},
		ownershipOGV(id) {
			this.ownershipError = null;
			if (id === '8da58370-82e4-4092-9210-95b9ef49fef7' && !this.userIsInRoles(['Support', 'MZ_Employee'])) {
				this.ownershipError = 'Федеральные организации может создавать только сотрудник Минздрава!';
			}
		},
		notesChange(id) {
			this.noteDisabled = false;
			this.nomenclatures.forEach((item) => {
				if (item.value === id) {
					this.defNomenclatures.every((name) => {
						if (name === item.name) {
							this.infoMO.noteId = '0f366d7c-e474-4ae3-a2b9-05164be8ef1f';
							this.noteDisabled = true;

							return false;
						} else {
							this.infoMO.noteId = null;
							this.noteDisabled = false;

							return true;
						}
					});
				}
			});
		},
		openPopUp() {
			this.infoMO.regionId = this.region.id;
			this.update = false;
			this.popupTitle = 'Добавить МО';
			this.open = true;
		},
		editMO(item, index) {
			Object.assign(this.infoMO, item);
			if (item.inn) {
				this.innClear = false;
			} else {
				this.innClear = true;
			}
			this.noteDisabled = false;
			this.notesChange(item.nomenclatureId);
			this.objectIndex = index;
			this.infoMO = item;
			this.popupTitle = 'Редактировать МО';
			this.open = true;
			this.update = true;
		},
		deliteUser(id) {
			/* eslint-disable */
			let yea = confirm('Вы уверены что хотите удалить?');
			if (yea) {
				this.delUser(id);
			}
		},
		sendEmail(login) {
			let yea = confirm('Отправить подтверждение на E-mail?');
			if (yea) {
				this.sendEmailConfirmedCode(login);
			}
			/* eslint-enable */
		},
		closePopup() {
			this.open = false;
			this.innClear = true;
			this.ownershipError = null;
			this.infoMO = {
				name: null,
				regionId: this.region.id,
				shortName: null,
				address: null,
				postAddress: null,
				ownerShipId: null,
				inn: null,
				isBranch: false,
				isClone: false,
				isMain: true,
				webSiteAddress: null,
				departmentBelongId: null,
				nomenclatureId: null,
				noteId: null,
				ambulator: false,
				stacionar: false,
				healthResort: false,
				psychiatric: false,
				parentId: null,
				deletedBy: null,
				yearStr: null,
				years: null
			};
            this.error = {
                name: { val: null, msg: 'Введите полное наименование МО' },
                regionId: { val: null, msg: 'Выберите Регион' },
                shortName: { val: null, msg: 'Введите краткое наименование МО' },
                address: { val: null, msg: 'Введите фактический адрес' },
                postAddress: { val: null, msg: 'Введите почтовый адрес' },
                ownerShipId: { val: null, msg: 'Выберите форма собственности' },
                inn: { val: null, msg: 'Введите ИНН' },
                webSiteAddress: { val: null, msg: 'Введите адрес официального сайта' },
                nomenclatureId: { val: null, msg: 'Выберите номенклатуру' },
                noteId: { val: null, msg: 'Выберите примечание' },
                isBranch: { val: null, msg: 'Выберите филиал это или нет' },
                ambulator: { val: null, msg: 'Выберите амбулаторные условия или нет' },
                stacionar: { val: null, msg: 'Выберите стационарные условия или нет ' },
                healthResort: { val: null, msg: 'Выберите санаторно-курортные условия или нет' },
                psychiatric: { val: null, msg: 'Выберите психиатрические условия или нет' },
                parentId: { val: null, msg: 'Выберите головную организацию' },
                years: { val: null, msg: 'Выберите включено в перечень какого года' }
            };
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		validation(infoMO) {
			if (infoMO.inn.length < 10) {
				this.error.inn.val = true;
			} else {
				console.log('infoMO', infoMO);

				this.error = {
					name: { val: null, msg: 'Введите полное наименование МО' },
					regionId: { val: null, msg: 'Выберите Регион' },
					shortName: { val: null, msg: 'Введите краткое наименование МО' },
					address: { val: null, msg: 'Введите фактический адрес' },
					postAddress: { val: null, msg: 'Введите почтовый адрес' },
					ownerShipId: { val: null, msg: 'Выберите форма собственности' },
					inn: { val: null, msg: 'Введите ИНН' },
					webSiteAddress: { val: null, msg: 'Введите адрес официального сайта' },
					nomenclatureId: { val: null, msg: 'Выберите номенклатуру' },
					noteId: { val: null, msg: 'Выберите примечание' },
					isBranch: { val: null, msg: 'Выберите филиал это или нет' },
					ambulator: { val: null, msg: 'Выберите амбулаторные условия или нет' },
					stacionar: { val: null, msg: 'Выберите стационарные условия или нет ' },
					healthResort: { val: null, msg: 'Выберите санаторно-курортные условия или нет' },
					psychiatric: { val: null, msg: 'Выберите психиатрические условия или нет' },
					parentId: { val: null, msg: 'Выберите головную организацию' },
					years: { val: null, msg: 'Выберите включено в перечень какого года' }
				};

				for (const key in infoMO) {
					if (Object.hasOwnProperty.call(infoMO, key)) {
						if (
							key === 'name' ||
							key === 'regionId' ||
							key === 'shortName' ||
							key === 'address' ||
							key === 'postAddress' ||
							key === 'ownerShipId' ||
							key === 'inn' ||
							key === 'webSiteAddress' ||
							key === 'nomenclatureId' ||
							key === 'noteId' ||
							key === 'ambulator' ||
							key === 'stacionar' ||
							key === 'healthResort' ||
							key === 'psychiatric' ||
							key === 'parentId' ||
							key === 'years' ||
							key === 'isBranch'
						) {
							if (infoMO[key] === null || infoMO[key].length === 0) {
								// Если Филиал выбран Да, то мы проверяем Головную организацию parentId
								if (infoMO.isBranch && key === 'parentId') {
									this.error[key].val = true;
								} else if (!infoMO.isBranch && key === 'parentId') {
									this.error[key].val = null;
								}

								// Если примечание выбрано Федеральное или Участвует в НОК, проверяем поле Включено в перечень
								if ((infoMO.noteId === '150a6a2c-e499-435d-9c2c-8488e60f5555' || infoMO.noteId === '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f') && key === 'years') {
									this.error[key].val = true;
								} else if (key === 'years' && infoMO.noteId !== '150a6a2c-e499-435d-9c2c-8488e60f5555' && infoMO.noteId !== '789200c6-8ddd-4dcd-abe5-3e3ab5e9ad7f') {
									this.error[key].val = null;
								}

								if (key !== 'parentId' && key !== 'years') {
									this.error[key].val = true;
								}
							}
						}
					}
				}

				let errors = Object.values(this.error).some((item) => {
					// if(item.val !== null)
					// console.log('Object.values(this.error).some((item)', item.msg, item.val);

					return item.val !== null;
				});
				if (!errors) {
					// console.log('good', this.error);
					this.sendForm();
				}
			}
		},
		sendForm() {
			if (!this.ownershipError) {
				if (!this.update) {
					this.addMO(this.infoMO).then((res) => {
						let addedMO = {};
						Object.assign(addedMO, this.infoMO);

						addedMO.id = res.data.id;
						this.infoMO.id = res.data.id;
						if (this.infoMO.isMain) {
							this.medOrgs.unshift(addedMO);
						} else {
							let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

							if (mainMO) {
								mainMO.hasBranch = true;

								if (this.opened !== mainMO.id) {
									this.openRow(mainMO);
								}

								mainMO.branches.unshift(addedMO);
							}
						}

						this.closePopup();
					});
				} else {
					this.updateMO(this.infoMO).then((res) => {
						if (res.errors) {
							console.log(res.errors);
						} else {
							if (this.infoMO.isMain) {
								let updatedMO = this.medOrgs[this.objectIndex];
								Object.assign(updatedMO, this.infoMO);
								updatedMO.branches = [];

								if (this.opened === updatedMO.id) {
									this.openRow(updatedMO);
									this.openRow(updatedMO);
								}
							} else {
								let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

								if (mainMO) {
									let updatedMO = mainMO.branches.find((x) => x.id === this.infoMO.id);

									if (updatedMO) {
										Object.assign(updatedMO, this.infoMO);
									}
								}
							}

							this.closePopup();
						}
					});
				}
			}
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params, true);
		},
		openBunnersPopUp(item) {
			if (item.oldId) {
				this.bunnersId = item.oldId;
			} else {
				this.bunnersId = item.id;
			}
			this.openBunners = true;
		},
		closeBunners() {
			this.bunnersId = '';
			this.openBunners = false;
		},
		getData(params, flag) {
			this.loadingTable = true;

			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 0;
			}

			this.getMedicalOrgs(params).then((res) => {
				this.medOrgs = res.data.data;
				this.medOrgs.map((item) => {
					item.active = false;
					return item;
				});
				this.totalCount = Math.ceil(res.data.totalRecords / this.rowsVisible);
				this.loadingTable = false;
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('MedicalOrgs/GetMedicalOrgsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Медицинские организации.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		downloadQr(item) {
			let type = 'ambulator';
			if (item.ambulator) {
				type = 'ambulator';
			} else if (item.stacionar) {
				type = 'stacionar';
			} else if (item.healthResort) {
				type = 'healthResort';
			} else {
				type = 'psychiatric';
			}
			this.getQr(item.id, type).then((res) => {
				let fileNameAndType = decodeURI('QR.png');

				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileNameAndType);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		}
	},
	mounted() {
		let regionId = this.getRegionId();

		this.params.filters[0].value1 = regionId;
		this.getData(this.params, true);

		let yearFilterOptions = [];

		this.getRegionYearData(regionId).then((res) => {
			for (let i = 0; i < res.data.availableYears.length; i += 1) {
				this.optionsYears.push({ year: res.data.availableYears[i] });
				yearFilterOptions.push({ name: res.data.availableYears[i].toString(), checked: false });
			}
		});

		let yearField = this.fields.find((x) => x.fieldName === 'YearStr');

		if (yearField !== undefined) {
			yearField.filterOptions = yearFilterOptions;
		}

		this.getMedicalOrgsByIdRegion(regionId).then((res) => {
			this.moArr = [];
			res.data.forEach((item) => {
				this.moArr.push({ name: item.moname, value: item.id });
			});
			this.moArr.sort((a, b) => compareAsc(a.name, b.name));
		});

		if (!this.userIsInRoles(['Support', 'MZ_Employee'])) {
			this.infoMO.regionId = regionId;
			this.regionDisabled = true;
		}

		this.getCurrentRegion().then((result) => {
			this.region = result;
		});

		this.getRegions().then((res) => {
			this.regions = [];
			res.data.forEach((item) => {
				this.regions.push({ name: item.Name, value: item.Id });
			});
		});

		this.getDepartmentBelong().then((res) => {
			this.departments = [];
			res.data.forEach((item) => {
				this.departments.push({ name: item.name, value: item.Id });
			});
		});

		this.getNomenclatures().then((res) => {
			this.nomenclatures = [];
			res.data.forEach((item) => {
				this.nomenclatures.push({ name: item.name, value: item.Id });
			});
			this.nomenclatures.sort((a, b) => compareAsc(a.name, b.name));
		});

		this.getNotes().then((res) => {
			this.notes = [];
			res.data.forEach((item) => {
				this.notes.push({ name: item.name, value: item.Id });
			});
		});

		this.getOwnership().then((res) => {
			this.ownership = [];
			res.data.forEach((item) => {
				this.ownership.push({ name: item.name, value: item.Id });
			});
		});
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.custom-error .vs__dropdown-toggle {
	border-radius: 10px;
	border: 1px solid red;
}

.custom-error-multiselect .multiselect__tags {
	border-radius: 10px;
	border: 1px solid red;
}

.default-filial-clone,
.default-filial-clone-2 {
	padding: 4px 8px;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	background: #a8b1ce;
	border-radius: 4px;
	width: 110px;
	color: #fff;
	text-align: center;
}
.preloader {
	z-index: 20;
	background: rgba(0, 0, 0, 0.09);
}

.td-name {
	position: sticky;
	background: #fff;
	min-width: 30%;
	max-width: 30%;
	left: 0;
	margin-top: -1px;
}

.td-name-head {
	margin-top: 0;
}

.table-mo {
	padding-left: 30%;
}

.table-mo:after {
	content: '';
	position: absolute;
	left: 0;
	height: calc(100% - 112px);
	top: 0;
	width: 30%;
	z-index: 8;
	background: #fff;
}

.select-label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.radio-group {
	margin-bottom: 20px;
}
.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}
.ava .icon,
.ava img {
	margin: auto;
}
.th-content .table-cell,
.table-cell {
	padding: 20px;
	vertical-align: top;
}
.th-content {
	white-space: nowrap;
}

.th-content span {
	font-weight: 600;
}

.table-cell span {
	margin: auto 0;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
}

.table-cell span.m-auto {
	margin: auto;
}

.table-cell a {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-decoration-line: underline;
	white-space: nowrap;
	color: #3377ff;
}

.table-cell a:hover {
	text-decoration: none;
}

.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.icon-control {
	cursor: pointer;
}

.icon-control:hover rect {
	fill: #3377ff;
}

.t-overflow {
	overflow: auto;
	min-height: 288px;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.input-box {
	position: relative;
	margin-bottom: 20px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
